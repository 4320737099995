(function() {
	if (!String.prototype.startsWith) {
		String.prototype.startsWith = function(searchString, position) {
			position = position || 0;
			return this.indexOf(searchString, position) === position
		}
	}

	function JsonToHTMLElement(object) {
		jsonObject = object
	}
	JsonToHTMLElement.prototype = {
		hasAttribute: function(key) {
			return (typeof jsonObject[key] != 'undefined')
		},
		getAttribute: function(key) {
			attValue = jsonObject[key];
			if (typeof attValue === 'object' || attValue instanceof Object)
				return JSON.stringify(attValue)
			else return attValue
		},
		parent: function() {
			return null
		},
		parentNode: function() {
			return null
		}
	}
	window.JsonToHTMLElement = JsonToHTMLElement;
	if (typeof window.CustomEvent === "function")
		return !1;

	function CustomEvent(event, params) {
		params = params || {
				bubbles: !1,
				cancelable: !1,
				detail: undefined
			};
		var evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt
	}
	CustomEvent.prototype = window.Event.prototype;
	window.CustomEvent = CustomEvent
})();
jQuery(function($) {
	var webTrekkHandlerClass = function(newConf) {
		if (!window.console)
			console = {
				log: function() {}
			};
		ui.configuration.webtrekk.webtrekkHandler = this;
		this.config = {};
		$.extend(this.config, newConf || {});
		this.deepUnescapeJSON(this.config.initData);
		this.config.defaultContentId = this.config.initData.contentId;
		this.config.baseContentId = this.config.initData.contentId;
		this.addeCommerceToATags();
		this.groups = {};
		this.observedElements = [];
		if (typeof(this.config.initData.customParameter) == 'undefined') {
			this.config.initData.customParameter = {
				12: "4.0-SNAPSHOT"
			}
		} else {
			this.config.initData.customParameter[12] = "4.0-SNAPSHOT"
		}
		this.config.initData.linkTrack = "";
		this.config.initData.linkTrackAttribute = "";
		this.config.initData.trackId = '808121318655954';
		this.startObservingElements();
		this.initializeASPS();
		ui.configuration.webtrekk.wt = new webtrekkV3(this.config.initData);
		this.sendPageRequest(this.config.initData.contentId, this.config.initData);
		window.addEventListener("webtrekkHelper.trackingEvent", function(e) {
			jsonObjectAsString = e.detail;
			ui.configuration.webtrekk.webtrekkHandler.myLog("WebtrekkHelper Event: trackingEvent triggered: " + jsonObjectAsString);
			jsonObject = ui.configuration.webtrekk.webtrekkHandler.getJSON(jsonObjectAsString, "Can't parse to JSON Object: " + jsonObjectAsString)
			ui.configuration.webtrekk.webtrekkHandler.fireTrackingRequest(jsonObject)
		});
		window.addEventListener("webtrekkHelper.changeConfigEvent", function(e) {
			jsonObjectAsString = e.detail;
			ui.configuration.webtrekk.webtrekkHandler.myLog("WebtrekkHelper Event: changeConfigEvent triggered: " + jsonObjectAsString);
			jsonObject = ui.configuration.webtrekk.webtrekkHandler.getJSON(jsonObjectAsString, "Can't parse to JSON Object: " + jsonObjectAsString)
			ui.configuration.webtrekk.webtrekkHandler.changeConfig(jsonObject)
		});
		ui.configuration.webtrekk.enabled = !0;
		window.dispatchEvent(new CustomEvent("webtrekkHelper.initialized"))
	};
	webTrekkHandlerClass.prototype = {
		_config: {
			initData: {}
		},
		callWebTrekkAPI: function(parameters) {
			this.myLog(parameters);
			ui.configuration.webtrekk.wt.sendinfo(parameters)
		},
		sendActionRequest: function(parameters) {
			if (typeof(parameters.linkId) != 'undefined') {
				this.myLog("Aktionsrequest mit AktionsId: " + parameters.linkId + " und ggf. weitere Parameter ");
				if (typeof(parameters) != 'undefined' && Object.keys(parameters).length > 1) {
					this.myLog(parameters)
				}
				this.callWebTrekkAPI(parameters)
			} else {
				this.myLog("Aktionsrequest kann nicht gesendet werden, weil der Parameter linkId fehlt!")
			}
		},
		sendPageRequest: function(contentId, parameters) {
			this.config.initData.contentId = contentId;
			var myConf = this.JSONClone(this.config.initData);
			myConf = $.extend(myConf, this.JSONClone(parameters) || {});
			this.myLog("Seitenrequest mit contentId: " + contentId + " und ggf. weitere Parameter ");
			if (typeof(parameters) != 'undefined' && Object.keys(parameters).length > 0) {
				this.myLog(parameters)
			}
			if (this.config.tempData && Object.keys(this.config.tempData).length > 0) {
				myConf = $.extend(myConf, this.JSONClone(this.config.tempData) || {});
				this.myLog("Parameters ergänzt mit Tempdatas!");
				this.myLog(this.config.tempData)
			}
			if (myConf.customEcommerceParameter && myConf.customEcommerceParameter[2]) {
				var cb2Param = myConf.customEcommerceParameter[2];
				if (!this.isCB2SessionAlive("wthCookie", cb2Param)) {
					myConf.customEcommerceParameter[2] = cb2Param + "_Start"
				}
			}
			myConf.customParameter[4] = window.location.href.replace(new RegExp("userToken=[^=&]*&?", 'g'), "");
			this.persistContentID(myConf.contentId);
			this.callWebTrekkAPI(myConf);
			this.config.tempData = null
		},
		persistContentID: function(contentId) {
			if (ui.configuration.webtrekk.wt) {
				ui.configuration.webtrekk.wt.contentId = contentId
			}
		},
		JSONClone: function(obj) {
			if (obj) {
				try {
					return JSON.parse(JSON.stringify(obj))
				} catch (err) {
					this.myLog("Fehler beim JSON Parsen Objekt: " + obj + "Fehler: " + err)
				}
			}
			return {}
		},
		isCB2SessionAlive: function(cookieBaseName, cb2Param) {
			var cb2CookieName = cookieBaseName + "cb2Sessions=";
			var browserCookieName = cookieBaseName + "BroswerSession=";
			var cb2Cookie;
			var browserCookie;
			var decodedCookie = decodeURIComponent(document.cookie);
			var ca = decodedCookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1)
				}
				if (c.indexOf(cb2CookieName) == 0) {
					cb2Cookie = c.substring(cb2CookieName.length, c.length)
				} else if (c.indexOf(browserCookieName) == 0) {
					browserCookie = c.substring(browserCookieName.length, c.length)
				}
			}
			if (cb2Cookie && browserCookie && cb2Cookie.indexOf(cb2Param) > -1)
				return !0;
			if (!browserCookie) {
				this.setCB2Session(cb2CookieName, browserCookieName, cb2Param + ",")
			} else {
				if (!cb2Cookie)
					cb2Cookie = "";
				cb2Cookie += cb2Param + ",";
				this.setCB2Session(cb2CookieName, browserCookieName, cb2Cookie)
			}
			return !1
		},
		setCB2Session: function(cb2CookieName, browserCookieName, cb2CookieValue) {
			var now = new Date();
			var expire = new Date();
			expire.setFullYear(now.getFullYear());
			expire.setMonth(now.getMonth());
			expire.setDate(now.getDate() + 1);
			expire.setHours(0);
			expire.setMinutes(0);
			expire.setSeconds(0);
			var expires = "expires=" + expire.toUTCString();
			document.cookie = cb2CookieName + encodeURIComponent(cb2CookieValue) + "; " + expires + "; path=/";
			document.cookie = browserCookieName + "SessionAlive; path=/"
		},
		formSubmit: function() {
			if (typeof(ui.configuration.webtrekk.wt) == 'undefined') {
				this.myLog("Webtrekk is not initialized")
			} else {
				ui.configuration.webtrekk.wt.formSubmit = !0;
				ui.configuration.webtrekk.wt.sendFormRequest();
				ui.configuration.webtrekk.wt.formSubmit = !1
			}
		},
		reload: function() {
			this.addeCommerceToATags();
			this.startObservingElements()
		},
		resend: function() {
			this.reload();
			this.sendPageRequest(this.config.initData.contentId, {})
		},
		readLinkID: function(element, webtrekkObject) {
			var linkId;
			if (element.hasAttribute("data-webtrekk-linkId")) {
				linkID = element.getAttribute("data-webtrekk-linkId");
				webtrekkObject.linkId = linkID;
				return
			}
			var area = null;
			var elementName = null;
			var name = null;
			var target = null;
			if (element.hasAttribute("data-webtrekk-linkId-element")) {
				elementName = element.getAttribute("data-webtrekk-linkId-element");
				if (element.hasAttribute("data-webtrekk-linkId-name")) {
					name = element.getAttribute("data-webtrekk-linkId-name")
				} else {
					return
				}
			} else {
				return
			}
			var tempElement = element;
			while (!tempElement.hasAttribute('data-webtrekk-linkId-area')) {
				tempElement = tempElement.parentNode
			}
			if (tempElement.hasAttribute("data-webtrekk-linkId-area")) {
				area = tempElement.getAttribute("data-webtrekk-linkId-area")
			}
			if (area == null)
				return;
			var target;
			baseString = "data-webtrekk-linkId-target";
			if (element.hasAttribute(baseString)) {
				target = element.getAttribute(baseString)
			} else if (element.hasAttribute(baseString + "-append-default-contentId")) {
				target = this.config.defaultContentId + element.getAttribute(baseString + "-append-default-contentId")
			} else if (element.hasAttribute(baseString + "-append-base-contentId")) {
				target = this.config.baseContentId + element.getAttribute(baseString + "-append-base-contentId")
			} else if (element.hasAttribute(baseString + "-append-contentId")) {
				target = this.config.initData.contentId + element.getAttribute(baseString + "-append-contentId")
			} else if (element.hasAttribute(baseString + "-href") && element.hasAttribute("href")) {
				href = element.getAttribute(baseString + "-href");
				if (!href || 0 === href.length) {
					href = element.getAttribute("href")
				}
				target = this.hrefToTarget(href)
			} else if (element.hasAttribute(baseString)) {
				target = element.getAttribute(baseString)
			}
			if (target == null)
				return;
			name = name.replace(new RegExp("\\s", 'g'), "_");
			target = target.replace(new RegExp("\\s", 'g'), "_");
			webtrekkObject.linkId = area + "." + elementName + "." + name + "." + target
		},
		readFormSubmit: function(element) {
			if (element.hasAttribute("data-webtrekk-formSubmit")) {
				this.formSubmit()
			}
		},
		readFormInstall: function(element, deleteAttribute) {
			if (element.hasAttribute("data-webtrekk-formInstall")) {
				if (typeof(ui.configuration.webtrekk.wt) == 'undefined') {
					this.myLog("Webtrekk is not initialized")
				} else {
					ui.configuration.webtrekk.wt.formTrackInstall(element);
					if (deleteAttribute) {
						this.removeAttribute(element, "data-webtrekk-formInstall")
					}
				}
			}
		},
		readContentID: function(element, webtrekkObject, variation, deleteAttribute) {
			var contentId = null;
			baseString = this.baseAttributePrefix(variation);
			if (element.hasAttribute(baseString + "append-default-contentId")) {
				contentId = this.config.defaultContentId + element.getAttribute(baseString + "append-default-contentId");
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "append-default-contentId")
				}
			} else if (element.hasAttribute(baseString + "append-base-contentId")) {
				contentId = this.config.baseContentId + element.getAttribute(baseString + "append-base-contentId");
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "append-base-contentId")
				}
			} else if (element.hasAttribute(baseString + "append-contentId")) {
				contentId = this.config.initData.contentId + element.getAttribute(baseString + "append-contentId");
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "append-contentId")
				}
			} else if (element.hasAttribute(baseString + "contentId")) {
				contentId = element.getAttribute(baseString + "contentId");
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "contentId")
				}
			}
			if (contentId !== null) {
				contentId = contentId.replace(new RegExp("\\s", 'g'), "_");
				webtrekkObject.contentId = contentId
			}
		},
		readProductParameter: function(element, webtrekkObject, variation, deleteAttribute) {
			baseString = this.baseAttributePrefix(variation);
			this.readDefaultStringParameter(element, webtrekkObject, variation, deleteAttribute, "product");
			this.readDefaultStringParameter(element, webtrekkObject, variation, deleteAttribute, "productStatus");
			this.readDefaultStringParameter(element, webtrekkObject, variation, deleteAttribute, "productCost");
			this.readDefaultStringParameter(element, webtrekkObject, variation, deleteAttribute, "orderValue");
			if (element.hasAttribute(baseString + "productCategory")) {
				var productCategory = element.getAttribute(baseString + "productCategory");
				productCategory = this.getJSON(productCategory, "Can't gen. ProductCategory JSON: " + productCategory);
				if (productCategory !== null) {
					webtrekkObject.productCategory = productCategory
				}
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "productCategory")
				}
			}
		},
		readSearchParameter: function(element, webtrekkObject, variation, deleteAttribute) {
			baseString = this.baseAttributePrefix(variation);
			if (element.hasAttribute(baseString + "internalSearch")) {
				internalSearch = element.getAttribute(baseString + "internalSearch");
				webtrekkObject.internalSearch = internalSearch;
				webtrekkObject.autoSendinfo = !0;
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "internalSearch")
				}
			}
			this.readCustomParameter(element, webtrekkObject, variation, deleteAttribute)
		},
		readContentGroupParameter: function(element, webtrekkObject, variation, deleteAttribute) {
			var contentGroup = null;
			baseString = this.baseAttributePrefix(variation);
			if (element.hasAttribute(baseString + "append-contentGroup")) {
				contentGroup = element.getAttribute(baseString + "append-contentGroup");
				var key = Object.keys(this.config.initData.contentGroup).length
				tmpObject = this.JSONClone(this.config.initData.contentGroup);
				tmpObject[key + 1] = contentGroup;
				contentGroup = tmpObject;
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "append-contentGroup")
				}
			} else if (element.hasAttribute(baseString + "contentGroup")) {
				contentGroup = element.getAttribute(baseString + "contentGroup");
				contentGroup = this.getJSON(contentGroup, "Can't gen. contentGroup JSON: " + contentGroup);
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "contentGroup")
				}
			} else if (element.hasAttribute(baseString + "append-page-to-contentGroup")) {
				if (this.config.nameChain[this.config.nameChain.length - 1]) {
					var pagename = this.config.nameChain[this.config.nameChain.length - 1]
					var key = Object.keys(this.config.initData.contentGroup).length
					tmpObject = this.JSONClone(this.config.initData.contentGroup);
					tmpObject[key + 1] = pagename;
					contentGroup = tmpObject;
					if (deleteAttribute) {
						this.removeAttribute(element, baseString + "append-page-to-contentGroup")
					}
				}
			}
			if (contentGroup !== null) {
				webtrekkObject.contentGroup = contentGroup
			}
		},
		readDefaultStringParameter: function(element, webtrekkObject, variation, deleteAttribute, parameterName) {
			if (element.hasAttribute(baseString + parameterName)) {
				var localParameter = element.getAttribute(baseString + parameterName);
				if (localParameter !== null) {
					webtrekkObject[parameterName] = localParameter
				}
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + parameterName)
				}
			}
		},
		readeCommerceParameter: function(element, webtrekkObject, variation, deleteAttribute) {
			this.readDefaultAppendableJSONParameters(element, webtrekkObject, variation, deleteAttribute, "customEcommerceParameter")
		},
		readCustomSessionParameter: function(element, webtrekkObject, variation, deleteAttribute) {
			this.readDefaultAppendableJSONParameters(element, webtrekkObject, variation, deleteAttribute, "customSessionParameter")
		},
		readCustomParameter: function(element, webtrekkObject, variation, deleteAttribute) {
			this.readDefaultAppendableJSONParameters(element, webtrekkObject, variation, deleteAttribute, "customParameter")
		},
		readCustomCampaignParameter: function(element, webtrekkObject, variation, deleteAttribute) {
			this.readDefaultAppendableJSONParameters(element, webtrekkObject, variation, deleteAttribute, "customCampaignParameter")
		},
		readDefaultAppendableJSONParameters: function(element, webtrekkObject, variation, deleteAttribute, parameterName) {
			var localParameter = null;
			baseString = this.baseAttributePrefix(variation);
			if (element.hasAttribute(baseString + "append-" + parameterName)) {
				localParameter = element.getAttribute(baseString + "append-" + parameterName);
				localParameter = this.getJSON(localParameter, "Can't gen. " + parameterName + " JSON: " + localParameter);
				tmpObject = this.JSONClone(this.config.initData[parameterName]);
				localParameter = $.extend(tmpObject, localParameter);
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + "append-" + parameterName)
				}
			} else if (element.hasAttribute(baseString + parameterName)) {
				localParameter = element.getAttribute(baseString + parameterName);
				localParameter = this.getJSON(localParameter, "Can't gen. " + parameterName + " JSON: " + localParameter);
				if (deleteAttribute) {
					this.removeAttribute(element, baseString + parameterName)
				}
			}
			if (localParameter !== null) {
				webtrekkObject[parameterName] = localParameter
			}
		},
		readformFieldDefaultValue: function(element, webtrekkObject, deleteAttribute) {
			if (element.hasAttribute("data-webtrekk-formFieldDefaultValue")) {
				var formFieldDefaultValue = element.getAttribute("data-webtrekk-formFieldDefaultValue");
				formFieldDefaultValue = this.getJSON(formFieldDefaultValue, "Can't gen. formFieldDefaultValue  JSON: " + formFieldDefaultValue);
				if (formFieldDefaultValue !== null) {
					webtrekkObject.formFieldDefaultValue = formFieldDefaultValue
				}
				if (deleteAttribute) {
					this.removeAttribute(element, "data-webtrekk-formFieldDefaultValue")
				}
			}
		},
		baseAttributePrefix: function(variation) {
			baseString = "data-webtrekk-"
			if (typeof(variation) !== 'undefined' && variation.length > 0) {
				baseString += variation + "-"
			}
			return baseString
		},
		analyseElement: function(element) {
			contIdTemp = this.config.initData.contentId
			this.readContentID(element, this.config.initData, "", !0);
			this.readeCommerceParameter(element, this.config.initData, "", !0);
			this.readCustomParameter(element, this.config.initData, "", !0);
			this.readContentGroupParameter(element, this.config.initData, "", !0);
			this.readCustomSessionParameter(element, this.config.initData, "", !0);
			this.readCustomCampaignParameter(element, this.config.initData, "", !0);
			this.readSearchParameter(element, this.config.initData, "", !0);
			this.readProductParameter(element, this.config.initData, "", !0);
			this.readFormInstall(element, !0);
			this.readformFieldDefaultValue(element, this.config.initData, !0);
			this.deepUnescapeJSON(this.config.initData);
			if (contIdTemp != this.config.initData.contentId)
				this.config.baseContentId = this.config.initData.contentId;
			var tmp = $.extend({}, this.config.tempData || {});
			this.readContentID(element, tmp, "temp", !0);
			this.readeCommerceParameter(element, tmp, "temp", !0);
			this.readCustomParameter(element, tmp, "temp", !0);
			this.readContentGroupParameter(element, tmp, "temp", !0);
			this.readCustomSessionParameter(element, tmp, "temp", !0);
			this.readCustomCampaignParameter(element, tmp, "temp", !0);
			this.readSearchParameter(element, tmp, "temp", !0);
			this.readProductParameter(element, tmp, "temp", !0);
			this.deepUnescapeJSON(tmp);
			this.config.tempData = tmp
		},
		handleElement: function(element) {
			var tmp = {};
			this.readLinkID(element, tmp);
			if (typeof(tmp.linkId) !== 'undefined') {
				this.readeCommerceParameter(element, tmp, "linkId");
				this.readCustomCampaignParameter(element, tmp, "linkId");
				this.readCustomParameter(element, tmp, "linkId");
				this.readContentGroupParameter(element, tmp, "linkId");
				this.readCustomSessionParameter(element, tmp, "linkId");
				this.readSearchParameter(element, tmp, "linkId");
				this.readProductParameter(element, tmp, "linkId");
				this.deepUnescapeJSON(tmp);
				this.sendActionRequest(tmp)
			};
			this.readFormSubmit(element);
			var tmp2 = {};
			this.readContentID(element, tmp2);
			this.readeCommerceParameter(element, tmp2);
			this.readCustomCampaignParameter(element, tmp2);
			this.readCustomParameter(element, tmp2);
			this.readContentGroupParameter(element, tmp2);
			this.readCustomSessionParameter(element, tmp2);
			this.readSearchParameter(element, tmp2);
			this.readProductParameter(element, tmp2);
			if (Object.keys(tmp2).length > 0) {
				this.deepUnescapeJSON(tmp2);
				if (typeof(tmp2.contentId) !== 'undefined') {
					this.sendPageRequest(tmp2.contentId, tmp2)
				} else {
					this.sendPageRequest(this.config.initData.contentId, tmp2)
				}
			}
		},
		removeAttribute: function(element, attribute) {
			if (element.removeAttribute) {
				element.removeAttribute(attribute)
			}
		},
		handleElementEvent: function(e) {
			var t;
			if (e.target) {
				t = e.target;
				if (!$(t).hasClass("webtrekkHelper_observe")) {
					t = $(t).parent(".webtrekkHelper_observe").get(0)
				}
			} else {
				t = e
			}
			if (!t) {
				return
			}
			if (t.hasAttribute("data-webtrekk-groupName")) {
				var groupName = t.getAttribute("data-webtrekk-groupName");
				if (groupName in this.groups) {
					if (this.groups[groupName] !== t) {
						this.groups[groupName] = t;
						this.handleElement(t)
					}
				} else {
					this.groups[groupName] = t;
					this.handleElement(t)
				}
			} else {
				this.handleElement(t)
			}
		},
		getJSON: function(e, errorMessage) {
			try {
				return JSON.parse(e.replace(new RegExp("'", 'g'), "\""))
			} catch (err) {
				this.myLog(errorMessage + " " + err);
				return null
			}
		},
		myLog: function(ele) {
			if (window.console) {
				console.log(ele)
			}
		},
		catchCallback: function(ele) {
			console.log(ele)
		},
		startObservingElements: function() {
			var elementsToObserve = $(".webtrekkHelper_observe");
			for (var i = 0; i < elementsToObserve.length; i++) {
				if (this.observedElements.indexOf(elementsToObserve[i]) < 0) {
					elementsToObserve[i].webtrekkListener = $.proxy(this.handleElementEvent, this);
					$(elementsToObserve[i]).on("click", elementsToObserve[i].webtrekkListener);
					this.observedElements.push(elementsToObserve[i])
				}
			}
			var elements = $(".webtrekkHelper_configs");
			for (var i = 0; i < elements.length; i++) {
				this.analyseElement(elements[i])
			}
		},
		resetObserver: function(elementToObserve) {
			if (elementToObserve.webtrekkListener) {
				$(elementToObserve).unbind("click", elementToObserve.webtrekkListener);
				$(elementToObserve).on("click", elementToObserve.webtrekkListener)
			} else if ($(elementToObserve).hasClass("webtrekkHelper_observe") && this.observedElements.indexOf(elementToObserve) < 0) {
				elementToObserve.webtrekkListener = $.proxy(this.handleElementEvent, this);
				$(elementToObserve).on("click", elementToObserve.webtrekkListener);
				this.observedElements.push(elementToObserve)
			}
		},
		addEcommerceParamter: function(element, href) {
			if (!element.hasAttribute("data-webtrekk-linkId-append-customEcommerceParameter") && !element.hasAttribute("data-webtrekk-linkId-customEcommerceParameter") && href) {
				var eCommerceJSON = "";
				var myRegexp = "\/([^\/\"<>\?]*[.]{1}([a-zA-z0-9]{2,4}))";
				var match = href.match(myRegexp);
				if (match !== null) {
					var linkFile = match[1];
					var extension = match[2];
					var allowedExtension = this.config.initData.linkTrackDownloads;
					if (allowedExtension !== null && allowedExtension.indexOf(extension.toLowerCase()) > -1) {
						eCommerceJSON = "'3':'" + linkFile + "'"
					}
				}
				if ((href.startsWith("www.") || href.startsWith("https://") || href.startsWith("http://") || href.startsWith("//")) && href.indexOf("union-investment") < 0) {
					if (eCommerceJSON.length > 0)
						eCommerceJSON += ",";
					eCommerceJSON += "'4':'" + href + "'"
				}
				if (eCommerceJSON.length > 0) {
					if (!$(element).hasClass("webtrekkHelper_observe")) {
						$(element).addClass("webtrekkHelper_observe")
					}
					element.setAttribute("data-webtrekk-linkId-customEcommerceParameter", "{" + eCommerceJSON + "}");
					if (window.console) {
						console.log("Element: " + element + ", eCommerce: " + "{" + eCommerceJSON + "}")
					}
				}
			}
		},
		addeCommerceToATags: function() {
			var myObj = this;
			$("a").each(function(index, elem) {
				var href = elem.getAttribute("href");
				myObj.addEcommerceParamter(elem, href)
			})
		},
		hrefToTarget: function(href) {
			if (href.startsWith("#")) {
				href = window.location.href
			}
			replaceDomain = document.location.origin;
			href = href.replace(replaceDomain, "");
			href = href.replace(/\.htm.*/i, "");
			href = href.replace(/\?.*/i, "");
			href = href.replace(/#.*/i, "");
			if ((href.startsWith("www.") || href.startsWith("https://") || href.startsWith("http://") || href.startsWith("//"))) {
				return "extern:" + href
			} else if (href.match("^/[^/].*") || href.indexOf(":") < 0) {
				if (href.startsWith("/")) {
					href = href.replace("/", "")
				}
				return href.replace(new RegExp("/", 'g'), ".")
			} else {
				return href
			}
		},
		addMissingRelTags: function(replaceDomain) {
			var myObj = this;
			$("a").each(function(index, elem) {
				var href = elem.getAttribute("href");
				myObj.addEcommerceParamter(elem, href);
				if (!elem.hasAttribute("rel")) {
					var rel = "unknown";
					if (href !== null) {
						if ((href.startsWith("http") || href.startsWith("//")) && href.indexOf("union-investment.") == -1) {
							rel = "extern";
							elem.setAttribute("rel", rel)
						}
					}
				}
			})
		},
		deepUnescapeJSON: function(object) {
			var val;
			for (var propName in object) {
				val = object[propName];
				if (typeof(object[propName]) == "string") {
					div = document.createElement('div');
					div.innerHTML = object[propName];
					val = div.textContent
				}
				if (typeof(object[propName]) == "object") {
					val = this.deepUnescapeJSON(object[propName])
				}
				object[propName] = val
			}
			return object
		},
		trackASPS: function(data) {
			if (ui.configuration.webtrekk.webtrekkHandler) {
				trackObj = ui.configuration.webtrekk.webtrekkHandler;
				if (!trackObj.aspsCalled) {
					trackObj.config.baseContentId = trackObj.config.defaultContentId + ".app_filialsuche";
					trackObj.aspsCalled = !0
				}
				cb2Parameter = {
					"2": "Filialsuche"
				};
				tmpObject = trackObj.JSONClone(trackObj.config.initData.customEcommerceParameter);
				cb2Parameter = $.extend(tmpObject, cb2Parameter);
				fillialfinderContentGroup = trackObj.JSONClone(trackObj.config.initData.contentGroup);
				key = Object.keys(fillialfinderContentGroup).length
				fillialfinderContentGroup[key + 1] = trackObj.config.nameChain[trackObj.config.nameChain.length - 1];
				baseParameters = {
					"customEcommerceParameter": cb2Parameter,
					"contentGroup": fillialfinderContentGroup
				};
				jsonResponse = JSON.parse(data);
				eventName = jsonResponse.filialfinder_event_name;
				eventParam = jsonResponse.filialfinder_event_param;
				eventParamExtra = jsonResponse.filialfinder_event_param_2;
				trackObj.myLog("EventName: " + eventName + ", EventParam: " + eventParam + ", EventParamExtra: " + eventParamExtra);
				if (eventName == "filialfinder.suche") {
					aktionsid = "Content.Button.Suche." + trackObj.config.baseContentId + ".Suchergebnisseite";
					trackObj.sendActionRequest({
						"linkId": aktionsid
					});
					contentId = trackObj.config.baseContentId + ".Suchergebnisseite";
					customParameter = {
						"1": eventParamExtra
					};
					tmpObject = trackObj.JSONClone(trackObj.config.initData.customParameter);
					customParameter = $.extend(tmpObject, customParameter);
					parameters = {
						"internalSearch": eventParam,
						"customParameter": customParameter,
					};
					finalParameters = $.extend(baseParameters, parameters);
					trackObj.sendPageRequest(contentId, finalParameters)
				} else if (eventName == "filialfinder.auswahl") {
					aktionsid = "Content.Link." + eventParam + "." + trackObj.config.baseContentId + "." + eventParam;
					trackObj.sendActionRequest({
						"linkId": aktionsid
					});
					contentId = trackObj.config.baseContentId + "." + eventParam;
					trackObj.sendPageRequest(contentId, baseParameters)
				} else if (eventName == "filialfinder.details") {
					aktionsid = "Content.Button.Details." + trackObj.config.baseContentId + "." + eventParam;
					trackObj.sendActionRequest({
						"linkId": aktionsid
					});
					contentId = trackObj.config.baseContentId + "." + eventParam;
					trackObj.sendPageRequest(contentId, baseParameters)
				} else if (eventName == "filialfinder.tabs") {
					aktionsid = "Content.Link." + eventParamExtra + "." + trackObj.config.baseContentId + "." + eventParam;
					trackObj.sendActionRequest({
						"linkId": aktionsid
					});
					contentId = trackObj.config.baseContentId + "." + eventParam;
					trackObj.sendPageRequest(contentId, baseParameters)
				} else if (eventName == "filialfinder.drucken") {
					aktionsid = "Content.Button.Drucken." + trackObj.config.initData.contentId;
					trackObj.sendActionRequest({
						"linkId": aktionsid
					})
				} else if (eventName == "filialfinder.email") {
					aktionsid = "Content.Link." + eventParam + ".mailto:" + eventParam;
					trackObj.sendActionRequest({
						"linkId": aktionsid
					})
				} else if (eventName == "filialfinder.webseite") {
					aktionsid = "Content.Link." + eventParam + ".extern:" + eventParam;
					trackObj.sendActionRequest({
						"linkId": aktionsid
					});
					ecommerceParameter = {
						"4": eventParam
					};
					tmpObject = trackObj.JSONClone(cb2Parameter);
					ecommerceParameter = $.extend(tmpObject, ecommerceParameter);
					if (ecommerceParameter !== null) {
						parameters = {
							"customEcommerceParameter": ecommerceParameter
						};
						finalParameters = $.extend(baseParameters, parameters);
						trackObj.sendPageRequest(trackObj.config.initData.contentId, finalParameters)
					}
				} else if (eventName == "filialfinder.beratungsanfrage") {
					aktionsid = "Content.Button.Beratungsanfrage." + trackObj.config.baseContentId + "." + eventParam;
					trackObj.sendActionRequest({
						"linkId": aktionsid
					})
				}
			}
		},
		initializeASPS: function() {
			var myObj = this;
			window.onmessage = function(e) {
				if ((e.origin == "https://aspsuche-dev.azurewebsites.net" || e.origin == "https://apps.virtual-identity.com") && ui.configuration.webtrekk.webtrekkHandler) {
					myObj.trackASPS(e.data)
				}
			};
			$(".asps").each(function(index, elem) {
				$(elem).on('tracking_action', function(data) {
					myObj.trackASPS(data.detail)
				})
			})
		},
		changeConfig: function(jsonObject) {
			if (jsonObject && (typeof jsonObject == 'object' || jsonObject instanceof Object)) {
				this.myLog("WebtrekkHelper setConfig called\n" + jsonObject)
				customElement = new JsonToHTMLElement(jsonObject);
				this.analyseElement(customElement)
			} else {
				this.myLog("WebtrekkHelper setConfig called, with empty Object\n" + jsObject)
			}
		},
		fireTrackingRequest: function(jsonObject) {
			if (jsonObject && (typeof jsonObject == 'object' || jsonObject instanceof Object)) {
				this.myLog("WebtrekkHelper JS-Tracking Request called\n" + jsonObject)
				customElement = new JsonToHTMLElement(jsonObject);
				this.handleElementEvent(customElement)
			} else {
				this.myLog("WebtrekkHelper JS-Tracking Request called, with empty Object\n" + jsObject)
			}
		}
	};
	new webTrekkHandlerClass(ui.configuration.webtrekk)
}(jQuery))
